import './App.css';
import React from 'react';

import {Container} from 'react-bootstrap'
import Login from './components/user.components/Login'



function App() {
  return (
    
    <Container className='all'>
      <Login />
     
      </Container>
    
    
  );
}

export default App;
