import * as React from 'react';
import {useState} from 'react'

function DashboardContact(){
    return (
<>
<div style={{width:'100%',height: '100%',display: 'flex', justifyContent: 'center'}}>
<h2 style={{color: 'white', fontSize:'12px'}}>Une question? N'hésitez pas à nous écrire à l'adresse suivante: demen.app.contact@gmail.com  </h2>
</div>
</>
    )
}

export default DashboardContact;